footer {
    background-color: #f5f5f5;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  
  .footer-section {
    flex: 1 1 33%;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  
  .footer-section h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .footer-section p {
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .footer-section ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .footer-section ul li {
    font-size: 14px;
    margin-bottom: 5px;
  }
  
  a {
    color: darkgreen;
    text-decoration: none;
    font-size: 14px;
  }
  