.Header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    
  }
  
  .Header-title {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .Header-title h1 {
    font-size: 36px;
  }
  
  .Header-title h2 {
    font-size: 18px;
    font-weight: normal;
    color: #333;
    margin-top: 10px;
  }
  
  .Header-ads {
    max-width: 100%;
    overflow: hidden;
    /* This is the aspect ratio for a 16:9 iframe padding-top: 30%;*/
    position: relative;
  }
  
  .Header-ads iframe {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  