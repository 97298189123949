.CurrentSquad {
  margin-top: 20px;
}

.CurrentSquad h2 {
  font-size: 24px;
  font-weight: bold;
  color: #003399;
  margin-bottom: 10px;
}

.CurrentSquad table {
  border-collapse: collapse;
  width: 100%;
}

.CurrentSquad th,
.CurrentSquad td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.CurrentSquad th {
  background-color: lightblue;
  color: #333;
  font-weight: bold;
}

.CurrentSquad td {
  font-size: 14px;
}

@media screen and (max-width: 600px) {
  .CurrentSquad table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
  
  .CurrentSquad th,
  .CurrentSquad td {
    min-width: 120px;
  }
  
  .CurrentSquad th:first-child,
  .CurrentSquad td:first-child {
    min-width: 150px;
  }
}
