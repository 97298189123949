@media screen and (max-width: 768px) {
    .rumored-players-container {
      overflow-x: auto;
    }
    .rumored-players-container table {
      width: 100%;
      min-width: 500px;
    }
    .rumored-players-container th,
    .rumored-players-container td {
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  
  .rumored-players-container {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .rumored-players-container h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #003399;
  }
  
  .rumored-players-container table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .rumored-players-container th,
  .rumored-players-container td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .rumored-players-container th {
    background-color: lightblue;
    font-weight: bold;
  }
  
  .rumored-players-container tr:nth-child(even) {
    
  }

  .rumored-players-container td:last-child {
    text-align: right;
  }
  
  .rumored-players-container td:last-child > * {
    display: block;
    margin: 0 auto;
  }
  
  .vote-button {
    background: linear-gradient(180deg, #0051ad, #003980);
    color: white;
    font-size: 14px;
    padding: 4px 8px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 4px;
    outline: none;
    width: 80px; /* add a fixed width */
    margin-bottom: 3px;
}

  
  .vote-button:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .vote-number {
    display: inline-block;
    padding: 0 8px;
    color: #ccc;
    font-size: 14px;
  }
  
  .vote-number.positive {
    color: green;
  }
  
  .vote-number.negative {
    color: red;
  }
  
  .vote-count {
    display: inline-block;
    width: 50px;
    text-align: center;
    margin-left: 10px;
    font-weight: bold;
    color: black;
  }
  .vote-count.positive {
    color: green;
  }
  .vote-count.negative {
    color: red;
  }
  
  
  
.arrivals-departures {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
  }
  
  .arrivals-departures h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #003399; /* blue */
  }
  
  .arrivals-departures ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
  }
  
  .arrivals-departures li {
    margin: 10px;
    font-size: 18px;
    color: #666666; /* gray */
  }
  
  .arrivals-departures li span {
    font-weight: bold;
    color: #003399; /* blue */
  }

  .table-container {
    display: flex;
    flex-direction: column;
    max-width: 800px; /* adjust as needed */
    margin: 0 auto; /* center the container horizontally */
    min-width: 100%;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd;
  }
  
  th, td {
    padding: 8px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  th {
    background-color: lightblue;
  }
  
  
.CurrentSquad {
  margin-top: 20px;
}

.CurrentSquad h2 {
  font-size: 24px;
  font-weight: bold;
  color: #003399;
  margin-bottom: 10px;
}

.CurrentSquad table {
  border-collapse: collapse;
  width: 100%;
}

.CurrentSquad th,
.CurrentSquad td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.CurrentSquad th {
  background-color: lightblue;
  color: #333;
  font-weight: bold;
}

.CurrentSquad td {
  font-size: 14px;
}

@media screen and (max-width: 600px) {
  .CurrentSquad table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
  
  .CurrentSquad th,
  .CurrentSquad td {
    min-width: 120px;
  }
  
  .CurrentSquad th:first-child,
  .CurrentSquad td:first-child {
    min-width: 150px;
  }
}

.RssFeeds {
    margin-top: 20px;
  }
  
  .RssFeeds h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #003399; /* blue */
  }
  
  .RssFeeds ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .RssFeeds li {
    margin-bottom: 10px;
  }
  
  .RssFeeds a {
    color: #333; /* dark grey */
    text-decoration: none;
  }
  
  .RssFeeds a:hover {
    color: #003399; /* blue */
  }
  
.Header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    
  }
  
  .Header-title {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .Header-title h1 {
    font-size: 36px;
  }
  
  .Header-title h2 {
    font-size: 18px;
    font-weight: normal;
    color: #333;
    margin-top: 10px;
  }
  
  .Header-ads {
    max-width: 100%;
    overflow: hidden;
    /* This is the aspect ratio for a 16:9 iframe padding-top: 30%;*/
    position: relative;
  }
  
  .Header-ads iframe {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
footer {
    background-color: #f5f5f5;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  
  .footer-section {
    flex: 1 1 33%;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  
  .footer-section h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .footer-section p {
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .footer-section ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .footer-section ul li {
    font-size: 14px;
    margin-bottom: 5px;
  }
  
  a {
    color: darkgreen;
    text-decoration: none;
    font-size: 14px;
  }
  
.App {
  font-family: Arial, sans-serif;
  margin: 0 auto;
  max-width: 800px;
  padding: 20px;
  background-image: linear-gradient(to bottom left, yellowgreen, lightblue);
}

.section-heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #003399; /* blue */
}

.list-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 10px;
}

.list-header {
  font-weight: bold;
  margin-bottom: 10px;
  color: #333; /* dark grey */
}

.list-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.list-item-name {
  font-weight: bold;
  color: #555; /* grey */
}

.list-item-value {
  font-style: italic;
}

.rss-feed-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
}

.rss-feed-item {
  margin-bottom: 10px;
}

.rss-feed-item-link {
  font-weight: bold;
  text-decoration: none;
  color: #003399; /* blue */
}

.rss-feed-item-link:hover {
  text-decoration: underline;
}




