.App {
  font-family: Arial, sans-serif;
  margin: 0 auto;
  max-width: 800px;
  padding: 20px;
  background-image: linear-gradient(to bottom left, yellowgreen, lightblue);
}

.section-heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #003399; /* blue */
}

.list-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 10px;
}

.list-header {
  font-weight: bold;
  margin-bottom: 10px;
  color: #333; /* dark grey */
}

.list-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.list-item-name {
  font-weight: bold;
  color: #555; /* grey */
}

.list-item-value {
  font-style: italic;
}

.rss-feed-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
}

.rss-feed-item {
  margin-bottom: 10px;
}

.rss-feed-item-link {
  font-weight: bold;
  text-decoration: none;
  color: #003399; /* blue */
}

.rss-feed-item-link:hover {
  text-decoration: underline;
}



