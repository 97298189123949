.RssFeeds {
    margin-top: 20px;
  }
  
  .RssFeeds h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #003399; /* blue */
  }
  
  .RssFeeds ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .RssFeeds li {
    margin-bottom: 10px;
  }
  
  .RssFeeds a {
    color: #333; /* dark grey */
    text-decoration: none;
  }
  
  .RssFeeds a:hover {
    color: #003399; /* blue */
  }
  