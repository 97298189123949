.arrivals-departures {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
  }
  
  .arrivals-departures h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #003399; /* blue */
  }
  
  .arrivals-departures ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
  }
  
  .arrivals-departures li {
    margin: 10px;
    font-size: 18px;
    color: #666666; /* gray */
  }
  
  .arrivals-departures li span {
    font-weight: bold;
    color: #003399; /* blue */
  }

  .table-container {
    display: flex;
    flex-direction: column;
    max-width: 800px; /* adjust as needed */
    margin: 0 auto; /* center the container horizontally */
    min-width: 100%;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd;
  }
  
  th, td {
    padding: 8px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  th {
    background-color: lightblue;
  }
  
  