@media screen and (max-width: 768px) {
    .rumored-players-container {
      overflow-x: auto;
    }
    .rumored-players-container table {
      width: 100%;
      min-width: 500px;
    }
    .rumored-players-container th,
    .rumored-players-container td {
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  
  .rumored-players-container {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .rumored-players-container h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #003399;
  }
  
  .rumored-players-container table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .rumored-players-container th,
  .rumored-players-container td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .rumored-players-container th {
    background-color: lightblue;
    font-weight: bold;
  }
  
  .rumored-players-container tr:nth-child(even) {
    
  }

  .rumored-players-container td:last-child {
    text-align: right;
  }
  
  .rumored-players-container td:last-child > * {
    display: block;
    margin: 0 auto;
  }
  
  .vote-button {
    background: linear-gradient(180deg, #0051ad, #003980);
    color: white;
    font-size: 14px;
    padding: 4px 8px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 4px;
    outline: none;
    width: 80px; /* add a fixed width */
    margin-bottom: 3px;
}

  
  .vote-button:hover {
    transform: scale(1.1);
  }

  .vote-number {
    display: inline-block;
    padding: 0 8px;
    color: #ccc;
    font-size: 14px;
  }
  
  .vote-number.positive {
    color: green;
  }
  
  .vote-number.negative {
    color: red;
  }
  
  .vote-count {
    display: inline-block;
    width: 50px;
    text-align: center;
    margin-left: 10px;
    font-weight: bold;
    color: black;
  }
  .vote-count.positive {
    color: green;
  }
  .vote-count.negative {
    color: red;
  }
  
  
  